var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3e60e66d02b2354ab156d6990685787b9e8c301e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";

const nextConfig = getConfig();
let config;

if (nextConfig) {
  config = nextConfig.publicRuntimeConfig;
} else {
  const env = window.__ENV || {};
  config = {
    sentryDSN: env.SENTRY_DSN,
    sentryEnvironment: env.SENTRY_ENVIRONMENT,
    sentryRelease: env.HEROKU_SLUG_COMMIT,
    sentrySampleRate: parseFloat(env.SENTRY_SAMPLE_RATE) || 1.0,
  };
}

const options = {
  dsn: config.sentryDSN,
  tracesSampleRate: config.sentrySampleRate,
  release: config.sentryRelease,
  environment: config.sentryEnvironment,
  integrations: [
    Sentry.rewriteFramesIntegration({
      iteratee: (frame) => {
        if (frame.filename) {
          frame.filename = decodeURI(frame.filename);
        }
        return frame;
      },
    }),
  ],
};

console.log("Init Sentry", options);

Sentry.init(options);
